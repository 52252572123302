<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="权限名称">
				<el-input v-model="form.name" placeholder="请输入权限名称"></el-input>
			</el-form-item>
			<el-form-item label="权限类型">
				<el-select v-model="form.type" placeholder="请选择">
				    <el-option
						v-for="item in [ { label : '全部' , value : '' } , { label : '菜单' , value : 1 } , { label : '按钮' , value : 2 } ]"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
				    </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属菜单编号ID">
				<el-input v-model="form.menuId" placeholder="请输入权限名称"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="permission_list" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-plus" @click="open_add_permission( 0 )" circle></el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<!-- <el-table-column
				prop="id"
				label="id"
				>
			</el-table-column> -->
			<el-table-column prop="name" label="权限名称">
				<template slot-scope="scope">
					<el-tag>{{ scope.row.name }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="menuId" label="权限所属菜单编号"></el-table-column>
			<el-table-column prop="uri" label="权限接口"></el-table-column>
			<el-table-column prop="value" label="权限值"></el-table-column>
			<el-table-column
				prop="type"
				label="类型"
				>
			>
				<template slot-scope="scope">
					<span v-if=" scope.row.type === 1 ">菜单</span>
					<span v-if=" scope.row.type === 2 ">按钮</span>
				</template>
			</el-table-column>
			<el-table-column
				prop="status"
				label="状态"
				>
			>
				<template slot-scope="scope">
					<el-tag :type="scope.row.status === 0 ? 'danger' : 'success'"  disable-transitions>{{ scope.row.status === 0 ? '停用' : '启用' }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				prop="createTime"
				label="创建时间"
				>
			</el-table-column>
			<el-table-column
				prop="modifyTime"
				label="最后修改时间"
				>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
			    <template slot-scope="scope">
					<el-button size="mini" icon="el-icon-edit" type="primary" plain @click=" open_add_permission( 1 , scope.row ) " style="margin-right: 5px;">编辑</el-button>
					<el-popconfirm
						title="是否要删除？"
						icon-color="red"
						@confirm="permission_delete( scope.row.id )"
					>
						<el-button slot="reference" size="mini" type="danger" plain icon="el-icon-delete">删除</el-button>
					</el-popconfirm>
			    </template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" :title=" type == 0 ? '新增' : '编辑' " :visible.sync="show" width="450px">
			<el-form ref="form" :model="permission_form" label-width="90px">
				<el-form-item label="权限名称" prop="name">
					<el-input v-model="permission_form.name" placeholder="请输入权限名称"></el-input>
				</el-form-item>
				<el-form-item label="所属菜单" prop="menuId">
					<el-select v-model="permission_form.menuId" placeholder="请选择">
					    <el-option
							v-for="item in menu"
							:key="item.id"
							:label="item.title"
							:value="item.id"
						>
					    </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="启用状态" prop="status">
					<el-radio-group v-model="permission_form.status">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="0">停用</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="permission_form.type">
						<el-radio :label="1">菜单</el-radio>
						<el-radio :label="2">按钮</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="权限接口" prop="uri">
					<el-input v-model="permission_form.uri" placeholder="请输入权限接口"></el-input>
				</el-form-item>
				<el-form-item label="权限值" prop="value">
					<el-input v-model="permission_form.value" placeholder="请输入权限值"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="add_permission" style="margin-right: 20px;">{{ type == 0 ? '新增' : '编辑' }}</el-button>
					<el-button @click="show = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				form : {
					menuId : "",
					name: "",
					pageSize : 10,
					startPage : 1,
					type : "",
				},
				total : 0,
				list : [],
				show: false,
				type : 0,			///表示是新增权限还是编辑权限
				menu : [],			//菜单列表
				permission_form : {
					menuId : "",
					name : "",
					status : 1,
					type : 1,
					uri : "",
					value : "",
				}
			}
		},
		created(){
			this.permission_list();
		},
		methods:{
			levelChange( level ){
				this.form.level = level;
			},
			permission_list(){
				this.loading = true;
				this.$api.permission_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			open_add_permission( type , data ){
				this.type = type;
				if( type == 1 ){
					let id = data.id;
					for( let k in this.permission_form ){
						this.permission_form[k] = data[k];
					}
					this.permission_form.id = id;
				}else{
					this.permission_form = {
						menuId : "",
						name : "",
						status : 1,
						type : 1,
						uri : "",
						value : "",
					}
				}
				if( this.menu.length == 0 ){
					this.$api.menu_getMenu().then( res => {
						if( res.code == 200 ){
							this.menu = res.data
							// .filter( item => {
							// 	return item.parentId != 0;
							// });
							if( this.type == 0 ){
								this.permission_form.menuId = this.menu[0].id;
							}
							this.show = true;
						}
					});
					return;
				}
				if( this.type == 0 ){
					this.permission_form.menuId = this.menu[0].id;
				}
				this.show = true;
			},
			add_permission(){
				this.$api[ this.type == 0 ? 'permission_add' : "permission_update" ]( this.permission_form ).then( res => {
					if( res.code == 200 ){
						this.$message({
							type: 'success',
							message: res.message
						});
						this.show = false;
						this.permission_list();
					}
				});
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.permission_list();
			},
			changePage(e) {
				this.form.startPage = e;
				this.permission_list();
			},
			permission_delete( permissionId ){
				this.$api.permission_delete( { permissionId } ).then( res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if( res.code == 200 ){
						if( this.list.length == 1 && this.form.startPage > 1 ){
							this.form.startPage = this.form.startPage - 1;
						}
						this.permission_list();
					}
				});
			},
		}
	}
</script>
<style lang="scss">
	.el-dialog__header{
		background: linear-gradient(135deg, #6b6be4 , #15d8f1);
	}
	.el-dialog__title{
		color:#fff
	}
	.el-dialog__header{
		.el-dialog__headerbtn{
			.el-dialog__close{
				color: #fff;
			}
		}
	}
</style>
